import React, { FC, HTMLAttributes, RefCallback, RefObject } from 'react';
// @ts-ignore
import BrowserSymbol from 'svg-baker-runtime/browser-symbol';
// @ts-ignore
import { assign } from 'es6-object-assign';
import { addSpriteSymbol, useIsomorphicLayoutEffect } from '../sprite';
import { SvgIcon } from '../SvgIcon';

const viewBox = '0 0 28 28';
const id = 'shopping_cart_outline_28';
const content = '<symbol fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" id="shopping_cart_outline_28"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.451 6.025C4.136 6 3.728 6 3.111 6H3a1 1 0 110-2h.148c.57 0 1.054 0 1.454.03.421.032.822.101 1.213.277a3.5 3.5 0 011.482 1.255c.094.142.172.288.238.438h14.782c.51 0 .958 0 1.322.032.38.034.792.112 1.17.348a2.5 2.5 0 011.086 1.46c.118.43.074.847-.003 1.22-.073.359-.202.788-.349 1.276l-1.401 4.67-.043.144c-.201.674-.377 1.266-.745 1.725a3 3 0 01-1.219.907c-.546.22-1.163.22-1.866.218h-9.093c-.452 0-.845 0-1.173-.025a3.032 3.032 0 01-1.037-.238 3 3 0 01-1.27-1.076 3.031 3.031 0 01-.405-.983c-.078-.32-.143-.708-.217-1.153L6.07 8.507c-.102-.609-.17-1.01-.245-1.318-.072-.295-.136-.431-.195-.52a1.5 1.5 0 00-.635-.538c-.097-.043-.242-.084-.545-.106zM8.014 8l.024.142 1.003 6.02c.081.49.134.802.192 1.039.055.225.1.309.129.353a1 1 0 00.423.358c.048.022.138.051.369.069.243.018.56.019 1.057.019h8.908c.943 0 1.131-.018 1.267-.073a1 1 0 00.407-.302c.091-.115.163-.29.433-1.193l1.39-4.63c.162-.541.264-.884.317-1.143.042-.208.035-.28.033-.292a.5.5 0 00-.216-.29c-.01-.005-.078-.034-.29-.052C23.198 8 22.84 8 22.275 8H8.014zm15.737.077h-.001zm.215.289v.001z" fill="currentColor" /><path d="M9 22a2 2 0 114 0 2 2 0 01-4 0zm9 0a2 2 0 114 0 2 2 0 01-4 0z" fill="currentColor" /></symbol>';

let isMounted = false;
function mountIcon() {
  if (!isMounted) {
    addSpriteSymbol(new BrowserSymbol({
      id: id,
      viewBox: viewBox,
      content: content,
    }));

    isMounted = true;
  }
}

export interface Icon28ShoppingCartOutlineProps extends HTMLAttributes<HTMLDivElement> {
  fill?: string;
  width?: number;
  height?: number;
  getRootRef?: RefCallback<HTMLDivElement> | RefObject<HTMLDivElement>;
}

const Icon28ShoppingCartOutline: FC<Icon28ShoppingCartOutlineProps> = (props) => {
  useIsomorphicLayoutEffect(() => {
    mountIcon();
  }, []);

  return React.createElement(SvgIcon, assign({}, props, {
    viewBox: viewBox,
    id: id,
    width: !isNaN(props.width) ? +props.width : 28,
    height: !isNaN(props.height) ? +props.height : 28,
  }));
};

(Icon28ShoppingCartOutline as any).mountIcon = mountIcon;

export default Icon28ShoppingCartOutline;
